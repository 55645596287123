import {
  CompanyCreateDocument,
  CompanyCreateMutation,
  CompanyCreateMutationVariables,
  CompanyForEditDocument,
  CompanyForEditQuery,
  CreateContactDocument,
  JobCreateDocument,
  JobCreateMutationVariables,
  JobForEditDocument,
  JobForEditQuery,
  JobUpdateDocument,
  JobUpdateMutationVariables,
  LanguagesDocument,
  ProjectCreateDocument,
  ProjectCreateMutationVariables,
  ProjectForEditDocument,
  ProjectForEditQuery,
  ProjectUpdateDocument,
  ProjectUpdateMutationVariables,
} from '@upper/graphql/internal'
import { useModalsManager } from '@upper/hooks'
import { Dialog, DialogContent } from '@upper/sapphire/ui'
import { extractUrl, omit, omitEmpty, pick } from '@upper/utils'
import { useMachine } from '@xstate/react'
import { Form, FormikProvider, useFormik } from 'formik'
import { AnimatePresence, LayoutGroup, useInView } from 'framer-motion'
import omitDeep from 'omit-deep'
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import toast from 'react-hot-toast'
import { twMerge } from 'tailwind-merge'
import { useClient, useQuery } from 'urql'
import { EditJobModalPayload, ModalAction } from '../../../const/modals'
import { useCreateSkills } from '../../../hooks/use-create-skills'
import { useSectionManager } from '../../../hooks/use-section-manager'
import {
  ADD_JOB_COMPANY_INPUT_DEFAULT,
  ADD_JOB_JOB_INPUT_DEFAULT,
  ADD_JOB_PROJECT_INPUT_DEFAULT,
} from '../../../providers/section/const'
import { getMultiStepValidationSchema } from '../../../providers/section/schema'
import multiStepMainForm from '../../../state-machine/multi-step-main-form'
import { CompanyStep } from './company-step'
import { JobStep } from './job-step'
import { LoadingStep } from './loading-step'
import { ProjectStep } from './project-step'
import { SideNav } from './side-nav'
import { SuccessStep } from './success-step'

type MultiStepManagerForm = {
  company: {
    state: 'select' | 'add'
    selected: Pick<
      CompanyForEditQuery['company'],
      'id' | 'name' | 'representative' | 'hubspotId'
    >
    add?: CompanyCreateMutationVariables['data'] & {
      hubspot: {
        shouldCreate: boolean
        selected: { name: string; id: string }
      }
    }
  }
  project: {
    state: 'select' | 'add' | 'edit'
    selected?: Pick<ProjectForEditQuery['project'], 'id' | 'name'>
    add?: ProjectCreateMutationVariables['data']
    edit?: ProjectUpdateMutationVariables['data']
  }
  job: (
    | JobCreateMutationVariables['data']
    | JobUpdateMutationVariables['data']
  ) & { hubspotShouldCreate: boolean }
}

const COMPANY_FORM_SECTIONS = [
  { name: 'Details', progress: 0, key: 'details', visible: false },
  {
    name: 'Representative',
    progress: 0,
    key: 'representative',
    visible: false,
  },
  { name: 'More details', progress: 0, key: 'more-details', visible: false },
  { name: 'Hubspot', progress: 0, key: 'hubspot', visible: false },
]

const PROJECT_FORM_SECTIONS = [
  { name: 'Details', progress: 0, key: 'details', visible: false },
  { name: 'Team', progress: 0, key: 'team', visible: false },
  { name: 'Communication', progress: 0, key: 'communication', visible: false },
  { name: 'Contact', progress: 0, key: 'contact', visible: false },
]

const JOB_FORM_SECTIONS = [
  { name: 'Details', progress: 0, key: 'details', visible: false },
  { name: 'Experience', progress: 0, key: 'experience', visible: false },
  { name: 'Freelancer', progress: 0, key: 'freelancer', visible: false },
  { name: 'Engagement', progress: 0, key: 'engagement', visible: false },
  { name: 'Budget', progress: 0, key: 'budget', visible: false },
  { name: 'More details', progress: 0, key: 'more-details', visible: false },
  { name: 'Hubspot', progress: 0, key: 'hubspot', visible: false },
]

export function MultiStepManager() {
  const client = useClient()
  const { hide: hideAddJob, visible: addJobVisible } = useModalsManager(
    ModalAction.ADD_JOB
  )
  const {
    hide: hideEditJob,
    payload: editJobPayload,
    visible: editJobVisible,
  } = useModalsManager<EditJobModalPayload>(ModalAction.EDIT_JOB)

  const [state, send, actorRef] = useMachine(multiStepMainForm)

  const [defaultLanguageResult] = useQuery({
    query: LanguagesDocument,
    variables: { filters: { name: 'english' } },
  })
  const englishLanguges = defaultLanguageResult.data?.languages

  const {
    sections: companySections,
    showSection: showCompanySection,
    hideSection: hideCompanySection,
  } = useSectionManager(COMPANY_FORM_SECTIONS)

  const {
    sections: projectSections,
    showSection: showProjectSection,
    hideSection: hideProjectSection,
  } = useSectionManager(PROJECT_FORM_SECTIONS)

  const {
    sections: jobSections,
    showSection: showJobSection,
    hideSection: hideJobSection,
  } = useSectionManager(JOB_FORM_SECTIONS)

  const [refs, setRefs] = useState<Record<string, HTMLDivElement>>({})
  const { createSkills } = useCreateSkills()
  const dialogContentRef = useRef<HTMLDivElement>(null)

  const initialValues: MultiStepManagerForm = useMemo(() => {
    return {
      company: {
        state: 'select',
        selected: null,
        add: {
          ...ADD_JOB_COMPANY_INPUT_DEFAULT,
          hubspot: {
            shouldCreate: true,
            selected: null,
          },
        },
      },
      project: {
        state: 'select',
        selected: null,
        add: ADD_JOB_PROJECT_INPUT_DEFAULT,
        edit: ADD_JOB_PROJECT_INPUT_DEFAULT,
      },
      job: {
        ...ADD_JOB_JOB_INPUT_DEFAULT,
        languages: englishLanguges || [],
        hubspotDeal: null,
        hubspotShouldCreate: false,
      },
    }
  }, [englishLanguges])

  const handleSubmit = async (
    values: MultiStepManagerForm,
    { setSubmitting }
  ) => {
    console.log(values)
    setSubmitting(true)
    let companyId =
      state.context.state === 'add'
        ? values.company.selected?.id
        : editJobPayload.companyId
    let companyName = values.company.selected?.name
    let companyHubspotId =
      values.company.selected?.hubspotId ||
      values.company.add?.hubspot?.selected?.id
    let projectId = values.project.selected?.id
    let projectName = values.project.selected?.name
    let jobId = null
    let jobName = values.job.name

    let companyCreatedData: CompanyCreateMutation['createCompany'] = null

    if (
      state.context.companyStep === 'createNew' &&
      state.context.state === 'add'
    ) {
      if (values.company.add.hubspot.shouldCreate) {
        companyHubspotId = null
      }

      const companyResult = await client.mutation(CompanyCreateDocument, {
        data: {
          ...omit(
            omitDeep(values.company.add, ['__typename']) as any,
            'hubspot'
          ),
          ...(values.company.add.hubspot.shouldCreate
            ? {}
            : { hubspotId: companyHubspotId }),
          url: extractUrl(values.company.add.url),
        },
        hubspot: {
          shouldCreate: values.company.add.hubspot.shouldCreate,
        },
      })
      if (!companyResult.error) {
        companyId = companyResult.data.createCompany.id
        companyName = companyResult.data.createCompany.name
        companyHubspotId = companyResult.data.createCompany.hubspotId
        companyCreatedData = companyResult.data.createCompany
      } else {
        toast.error(`Failed to create company: ${companyResult.error.message}`)
        setSubmitting(false)
        return
      }
    }

    if (state.context.projectStep === 'createNew') {
      const contact = values.project.add.contact
      let contactId = values.project.add.contactId
      if (contactId === '') {
        const createContactResult = await client.query(CreateContactDocument, {
          data: { ...contact, companyId },
        })

        if (createContactResult.error) {
          throw Error(createContactResult.error.message)
        }

        contactId = createContactResult.data?.createContact.id
      } else if (contactId === 'same-as-company') {
        contactId =
          values?.company?.selected?.representative?.id ??
          companyCreatedData?.representativeId
      }

      const projectResult = await client.mutation(ProjectCreateDocument, {
        data: {
          ...omitDeep(omitEmpty(omit(values.project.add, 'contact')), [
            '__typename',
          ]),
          companyId,
          contactId,
        },
      })
      if (!projectResult.error) {
        projectId = projectResult.data.createProject.id
        projectName = projectResult.data.createProject.name
      } else {
        toast.error(`Failed to create project: ${projectResult.error.message}`)
        setSubmitting(false)
        return
      }
    }

    // edit project
    if (state.context.projectStep === 'edit') {
      const contact = values.project.edit.contact
      let contactId = values.project.edit.contactId
      if (contactId === '') {
        const createContactResult = await client.query(CreateContactDocument, {
          data: { ...contact, companyId },
        })

        if (createContactResult.error) {
          throw Error(createContactResult.error.message)
        }

        contactId = createContactResult.data?.createContact.id
      } else if (contactId === 'same-as-company') {
        contactId = values?.company?.selected?.representative?.id
      }

      const projectResult = await client.mutation(ProjectUpdateDocument, {
        id: editJobPayload.projectId,
        data: {
          ...omitDeep(omitEmpty(omit(values.project.edit, 'contact')), [
            '__typename',
          ]),
          companyId,
          contactId,
        },
      })
      if (!projectResult.error) {
        projectId = projectResult.data.updateProject.id
        projectName = projectResult.data.updateProject.name
      } else {
        toast.error(`Failed to create project: ${projectResult.error.message}`)
        setSubmitting(false)
        return
      }
    }

    const skills = await createSkills([
      ...values.job.requiredSkills,
      ...values.job.niceToHaveSkills,
    ])

    if (state.context.state === 'add') {
      const jobResult = await client.mutation(JobCreateDocument, {
        data: {
          ...omit(
            omitDeep(values.job, ['__typename']) as any,
            'hubspotDeal',
            'hubspotShouldCreate'
          ),
          companyId,
          projectId,
          acceptanceProcess: values.job.acceptanceProcess || null,
          commitmentInfo: values.job.acceptanceProcess || null,
          startDate: values.job.startDate || null,
          requiredSkills: values.job.requiredSkills?.map((skill) => ({
            id: skill.id?.startsWith('create')
              ? skills?.find((cs) => cs.name === skill.name)?.id
              : skill.id,
            name: skill.name,
          })),
          niceToHaveSkills: values.job.niceToHaveSkills?.map((skill) => ({
            id: skill.id?.startsWith('create')
              ? skills?.find((cs) => cs.name === skill.name)?.id
              : skill.id,
            name: skill.name,
          })),
        },
        hubspot: {
          shouldCreate: values.job.hubspotShouldCreate,
          companyId: companyHubspotId,
          dealId: values.job.hubspotShouldCreate
            ? null
            : values.job.hubspotDeal?.id,
        },
      })

      if (jobResult.error) {
        toast.error(`Failed to create job: ${jobResult.error.message}`)
        setSubmitting(false)
        return
      } else {
        jobId = jobResult.data.createJob.id
        jobName = jobResult.data.createJob.name
      }
    }

    if (state.context.state === 'edit') {
      const jobResult = await client.mutation(JobUpdateDocument, {
        id: editJobPayload.id,
        data: {
          ...omit(
            omitDeep(values.job, ['__typename']) as any,
            'hubspotDeal',
            'hubspotShouldCreate'
          ),
          companyId,
          projectId,
          acceptanceProcess: values.job.acceptanceProcess || null,
          commitmentInfo: values.job.acceptanceProcess || null,
          startDate: values.job.startDate || null,
          requiredSkills: values.job.requiredSkills?.map((skill) => ({
            id: skill.id?.startsWith('create')
              ? skills?.find((cs) => cs.name === skill.name)?.id
              : skill.id,
            name: skill.name,
          })),
          niceToHaveSkills: values.job.niceToHaveSkills?.map((skill) => ({
            id: skill.id?.startsWith('create')
              ? skills?.find((cs) => cs.name === skill.name)?.id
              : skill.id,
            name: skill.name,
          })),
        },
        hubspot: {
          shouldCreate: values.job.hubspotShouldCreate,
          companyId: companyHubspotId,
          dealId: values.job.hubspotShouldCreate
            ? null
            : values.job.hubspotDeal?.id,
        },
      })

      if (jobResult.error) {
        toast.error(`Failed to create job: ${jobResult.error.message}`)
        setSubmitting(false)
        return
      } else {
        jobId = jobResult.data.updateJob.id
        jobName = jobResult.data.updateJob.name
      }
    }
    send({
      type: 'SUCCESS',
      params: {
        company: { id: companyId, name: companyName },
        project: { id: projectId, name: projectName },
        job: { id: jobId, name: jobName },
      },
    })
  }

  const formik = useFormik<typeof initialValues>({
    initialValues: initialValues,
    validationSchema: getMultiStepValidationSchema(),
    onSubmit: handleSubmit,
  })

  const [projectLoading, setProjectLoading] = useState(true)
  const [jobLoading, setJobLoading] = useState(true)

  const loadInitialValues = useCallback(
    async (payload: EditJobModalPayload) => {
      await new Promise((resolve) => setTimeout(resolve, 500))
      const jobResult = await client
        .query(
          JobForEditDocument,
          { id: payload.id },
          { requestPolicy: 'network-only' }
        )
        .toPromise()
      if (!jobResult.error) {
        setJobLoading(false)
      }
      const projectResult = await client
        .query(
          ProjectForEditDocument,
          { id: payload.projectId },
          { requestPolicy: 'network-only' }
        )
        .toPromise()
      if (!projectResult.error) {
        setProjectLoading(false)
      }
      const companyResult = await client
        .query(
          CompanyForEditDocument,
          { id: payload.companyId },
          { requestPolicy: 'network-only' }
        )
        .toPromise()

      formik.setValues({
        company: {
          state: 'select',
          selected: {
            id: payload.companyId,
            name: companyResult.data.company.name,
            hubspotId: companyResult.data.company.hubspotId,
            representative: companyResult.data.company.representative,
          },
        },
        project: {
          state: 'edit',
          selected: {
            id: payload.projectId,
            name: projectResult.data.project.name,
          },
          edit: projectDataAsInput(projectResult.data.project),
        },
        job: {
          ...jobDataAsInput(jobResult.data.job),
        },
      })

      await new Promise((resolve) => setTimeout(resolve, 500))
      send({
        type: 'EDIT_JOB',
        params: {
          companyId: editJobPayload.companyId,
          projectId: editJobPayload.projectId,
          jobId: editJobPayload.id,
        },
      })
    },
    [
      client,
      editJobPayload?.companyId,
      editJobPayload?.id,
      editJobPayload?.projectId,
      formik,
      send,
    ]
  )

  const registerRef = useCallback((key: string, ref: HTMLDivElement) => {
    setRefs((prev) => ({ ...prev, [key]: ref }))
  }, [])

  const handleReset = useCallback(() => {
    send({ type: 'RESET' })
    formik.resetForm()
  }, [formik, send])

  const handleCancel = useCallback(() => {
    if (state.context.state === 'add') {
      hideAddJob()
    } else if (state.context.state === 'edit') {
      hideEditJob()
    }
    send({ type: 'RESET' })
    formik.resetForm()
  }, [formik, hideAddJob, hideEditJob, send, state.context.state])

  const onOpenChange = useCallback(
    (visible: boolean) => {
      if (!visible) {
        state.context.state === 'add' ? hideAddJob() : hideEditJob()
      }
      send({ type: 'RESET' })
      formik.resetForm()
    },
    [formik, hideAddJob, hideEditJob, send, state.context.state]
  )

  useEffect(() => {
    const subscription = actorRef.subscribe((state) => {
      if (state.matches('projectStep')) {
        formik.setFieldValue('project.state', state.context.projectStep)
      }
      if (state.matches('companyStep')) {
        formik.setFieldValue('company.state', state.context.companyStep)
      }
      dialogContentRef.current?.scrollTo(0, 0)
    })

    return subscription.unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actorRef])

  useEffect(() => {
    if (addJobVisible === true) {
      send({ type: 'ADD_JOB' })
    } else if (editJobVisible === true && editJobPayload) {
      send({ type: 'LOADING' })
      loadInitialValues(editJobPayload)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addJobVisible, editJobVisible, editJobPayload, send])

  const isCompanyValid = useMemo((): boolean => {
    if (state.context.state === 'edit') return true
    return (
      (state.context.companyStep === 'selectExisting' &&
        formik.values.company.selected !== null) ||
      (state.context.companyStep === 'createNew' &&
        formik.errors.company === undefined &&
        formik.touched.company !== undefined)
    )
  }, [
    formik.errors.company,
    formik.touched.company,
    formik.values.company.selected,
    state.context.companyStep,
    state.context.state,
  ])

  const isProjectValid = useMemo(() => {
    if (state.context.state === 'edit') {
      return formik.errors.project === undefined
    } else {
      return (
        (state.context.projectStep === 'selectExisting' &&
          formik.values.project.selected !== null) ||
        (state.context.projectStep === 'createNew' &&
          formik.errors.project === undefined &&
          formik.touched.project !== undefined)
      )
    }
  }, [
    formik.errors.project,
    formik.touched.project,
    formik.values.project.selected,
    state.context.projectStep,
    state.context.state,
  ])

  const isJobValid = useMemo(() => {
    if (state.context.state === 'edit') {
      return formik.errors.job === undefined
    } else {
      return formik.errors.job === undefined && formik.touched.job !== undefined
    }
  }, [formik.errors.job, formik.touched.job, state.context.state])

  const visible = addJobVisible || editJobVisible

  return (
    <Dialog open={visible} onOpenChange={onOpenChange} modal={false}>
      <DialogContent
        className={twMerge(
          '!shadow-2xl transition-all',
          '@container/main flex h-[calc(100vh-5rem)] w-[90vw] max-w-none gap-0 overflow-clip rounded-xl border border-slate-200 bg-white p-0 md:w-[calc(50vw+300px)]',
          state.matches('success') && '!w-[50vw]'
        )}
        autoFocus={false}
      >
        <AnimatePresence>
          <LayoutGroup>
            {!state.matches('success') && !state.matches('loading') && (
              <SideNav
                state={state}
                send={send}
                isSubmitting={formik.isSubmitting}
                isCompanyValid={isCompanyValid}
                isProjectValid={isProjectValid}
                isJobValid={isJobValid}
                onCancel={handleCancel}
                onSave={() => formik.handleSubmit()}
                companySections={companySections}
                projectSections={projectSections}
                jobSections={jobSections}
                refs={refs}
                companyName={
                  state.context.companyStep === 'createNew'
                    ? formik.values.company.add?.name
                    : formik.values.company.selected?.name
                }
                projectName={
                  state.context.projectStep === 'createNew'
                    ? formik.values.project.add?.name
                    : formik.values.project.selected?.name
                }
                jobName={formik.values.job?.name}
              />
            )}
          </LayoutGroup>
        </AnimatePresence>
        <section
          ref={dialogContentRef}
          className={twMerge(
            'h-full w-full overflow-auto bg-slate-100 p-2',
            formik.isSubmitting && 'pointer-events-none opacity-75',
            state.matches('success') && 'bg-white'
          )}
        >
          {!state.matches('success') && !state.matches('loading') && (
            <FormikProvider value={formik}>
              <Form className="h-full min-h-full">
                {state.context.state === 'add' &&
                  state.matches('companyStep') && (
                    <CompanyStep
                      state={state}
                      send={send}
                      canContinue={isCompanyValid}
                      registerRef={registerRef}
                      showSection={showCompanySection}
                      hideSection={hideCompanySection}
                    />
                  )}
                {state.matches('projectStep') && (
                  <ProjectStep
                    registerRef={registerRef}
                    state={state}
                    send={send}
                    showSection={showProjectSection}
                    hideSection={hideProjectSection}
                    canContinue={isProjectValid}
                  />
                )}
                {state.matches('jobStep') && (
                  <JobStep
                    registerRef={registerRef}
                    showSection={showJobSection}
                    hideSection={hideJobSection}
                  />
                )}
              </Form>
            </FormikProvider>
          )}
        </section>
        <AnimatePresence>
          {state.matches('loading') && (
            <LoadingStep
              jobName={editJobPayload?.name}
              projectName={editJobPayload?.projectName}
              projectLoading={projectLoading}
              jobLoading={jobLoading}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {state.matches('success') && (
            <SuccessStep
              state={state}
              onReset={handleReset}
              onCancel={handleCancel}
            />
          )}
        </AnimatePresence>
      </DialogContent>
    </Dialog>
  )
}

export function FormSection({ children }: PropsWithChildren) {
  return (
    <div className="w-full rounded-lg border border-slate-100 p-0">
      {children}
    </div>
  )
}

export function FormSectionHeader({
  id,
  children,
  onEnter,
  onLeave,
}: PropsWithChildren<{
  id?: string
  onEnter?: () => void
  onLeave?: () => void
}>) {
  const ref = useRef(null)
  const isInView = useInView(ref, { amount: 'some', margin: '0% 0%' })

  useEffect(() => {
    if (isInView) onEnter?.()
    else onLeave?.()
  }, [isInView])

  return (
    <div
      ref={ref}
      className="shadow-inner-stroke rounded-xl bg-slate-200 px-3 py-2"
      id={id}
    >
      {children}
    </div>
  )
}

function projectDataAsInput(project: ProjectForEditQuery['project']) {
  return {
    ...ADD_JOB_PROJECT_INPUT_DEFAULT,
    ...omitEmpty(
      pick(
        project,
        'name',
        'description',
        'phase',
        'state',
        'platforms',
        'skills',
        'teamState',
        'teamSize',
        'teamEnvironment',
        'teamEnvironmentOther',
        'onboardingProcess',
        'managementMethodology',
        'managementMethodologyOther',
        'communicationModel',
        'contact',
        'contactId'
      )
    ),
  }
}

function jobDataAsInput(job: JobForEditQuery['job']) {
  return {
    ...ADD_JOB_JOB_INPUT_DEFAULT,
    hubspotShouldCreate: job.hubspotDealId === null,
    ...omitEmpty(
      pick(
        job,
        'name',
        'teamSize',
        'description',
        'requiredSkills',
        'niceToHaveSkills',
        'languages',
        'location',
        'hourlyRateBudget',
        'annualSalaryBudget',
        'contractType',
        'emId',
        'expertId',
        'fellowId',
        'isPausedSourcing',
        'position',
        'status',
        'mindset',
        'commitment',
        'commitmentInfo',
        'startDate',
        'length',
        'locationOnSite',
        'locationOnSitePercentage',
        'requiredExperience',
        'niceToHaveExperience',
        'length',
        'acceptanceProcess',
        'roleGoals',
        'hubspotDeal',
        'em',
        'dealSource',
        'dealType'
      )
    ),
  }
}

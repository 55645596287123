import {
  AssessmentType,
  TalentAvailability,
  TalentKycStatus,
  TalentSource,
  TalentStatus,
} from '@upper/graphql/internal'
import { capitalize, words } from 'voca'

export const TALENT_STATUSES: TalentStatus[] = [
  TalentStatus.AppliedToUpper,
  TalentStatus.Invited,
  TalentStatus.Verification,
  TalentStatus.PersonalInterview,
  TalentStatus.Challenge,
  TalentStatus.TechnicalInterview,
  TalentStatus.Onboarding,
  TalentStatus.Active,
  TalentStatus.Rejected,
  TalentStatus.Registered,
]

export const TALENT_VETTING_PERMANENT_STATUSES: TalentStatus[] = [
  TalentStatus.Verification,
  TalentStatus.PersonalInterview,
  TalentStatus.Challenge,
  TalentStatus.TechnicalInterview,
  TalentStatus.Onboarding,
  TalentStatus.Rejected,
]

export const TALENT_VETTING_STATUSES: TalentStatus[] = [
  TalentStatus.AppliedToUpper,
  TalentStatus.Invited,
  TalentStatus.Verification,
  TalentStatus.PersonalInterview,
  TalentStatus.Challenge,
  TalentStatus.TechnicalInterview,
  TalentStatus.Onboarding,
  TalentStatus.Active,
  TalentStatus.Rejected,
  TalentStatus.Registered,
]

export const ASSESSMENT_TYPE_FOR_TALENT_STATUS: Record<
  TalentStatus,
  AssessmentType | null
> = {
  [TalentStatus.Applied]: null,
  [TalentStatus.AppliedToUpper]: null,
  [TalentStatus.Invited]: null,
  [TalentStatus.PersonalInterview]: AssessmentType.Screening,
  [TalentStatus.Challenge]: AssessmentType.Challenge,
  [TalentStatus.TechnicalInterview]: AssessmentType.TechInterview,
  [TalentStatus.Onboarding]: null,
  [TalentStatus.Active]: null,
  [TalentStatus.Rejected]: null,
  [TalentStatus.Registered]: null,
  [TalentStatus.Deactivated]: null,
  [TalentStatus.Deleted]: null,
  [TalentStatus.Waitlist]: null,
  [TalentStatus.Unknown]: null,
  [TalentStatus.Verification]: null,
}

export const TALENT_VETTING_STATUS_LABEL: Partial<
  Record<TalentStatus, string>
> = {
  AppliedToUpper: 'Applied',
  Invited: 'Invited',
  Challenge: 'Challenge',
  Verification: 'KYC',
  PersonalInterview: 'Screening',
  TechnicalInterview: 'Technical Interview',
  Onboarding: 'Onboarding',
  Active: 'Active',
  Rejected: 'Rejected',
  Registered: 'Registered',
  Deactivated: 'Deactivated',
  Unknown: 'Unknown',
  Deleted: 'Deleted',
  Waitlist: 'Waitlist',
  Applied: 'Applied',
}

export const TALENT_ASSESSMENT_SHORTNAME_FOR_TYPE: Record<AssessmentType, string> = {
  AI: 'AI',
  Challenge: 'CH',
  ClientEngagementFeedback: 'CEF',
  Custom: "C",
  Matching: "M",
  Screening: "S",
  TechInterview: "TI"
}

export const STYLE_FOR_TALENT_STATE: Record<
  'innactive' | 'active' | 'rest',
  string
> = {
  active: 'bg-white',
  innactive: 'bg-red-100 text-red-800',
  rest: 'bg-yellow-50 text-yellow-800',
}

export const TALENT_STATE_FOR_TALENT_STATUS = function (status: TalentStatus) {
  switch (status) {
    case TalentStatus.Active:
      return 'active'
    case TalentStatus.Deactivated:
      return 'innactive'
    case TalentStatus.Rejected:
      return 'innactive'
    case TalentStatus.Deleted:
      return 'innactive'
    default:
      return 'rest'
  }
}

export const TALENT_STATUS_OPTIONS = TALENT_STATUSES.map((status) => ({
  label: words(status).join(' '),
  value: status,
}))

export const TALENT_KYC_STATUS_OPTIONS = [{ label: 'Not Set', value: null }, ...Object.keys(TalentKycStatus).map(
  (k) => ({
    label: words(k).join(' '),
    value: k,
  })
)]

export const TALENT_VETTING_STATUS_OPTIONS = TALENT_VETTING_STATUSES.map(
  (status) => ({
    label: TALENT_VETTING_STATUS_LABEL[status],
    value: status,
  })
)

export const AddFreelancerStatusSelectOptions = Object.keys(TalentSource).map(
  (k) => ({ label: capitalize(words(TalentSource[k]).join(' ')), value: k })
)

export const TALENT_AVAILABILITY_OPTIONS = Object.keys(TalentAvailability).map(
  (k) => ({
    label: words(TalentAvailability[k]).join(' '),
    value: k,
  })
)

export const TALENT_STATUSES_TO_NOT_REJECT: TalentStatus[] = [
  TalentStatus.Onboarding,
  TalentStatus.Active,
  TalentStatus.Deactivated,
  TalentStatus.Rejected,
  TalentStatus.Deleted,
]
